<template>
  <div>
    <span class="font-weight-bold">Note: </span>
    <b-form-textarea
      @input="addNotes"
      v-model="booking.notes"
    />
  </div>
</template>

<script>
import { BFormTextarea } from 'bootstrap-vue';
import { computed } from '@vue/composition-api';
import useBookingUtils from '@/modules/booking/composables/useBookingUtils';
import store from '@/store';

export default {
  setup() {
    const { storeBooking } = useBookingUtils();

    const booking = computed(() => store.getters['booking/booking']);

    const addNotes = () => {
      storeBooking(booking.value);
    };

    return {
      // Reactive
      booking,

      // Method
      addNotes
    };
  },
  components: { BFormTextarea },
};
</script>
