<template>
    <b-card>
        <b-card-header>
            <div class="d-flex justify-content-between align-items-center w-100">
                <b-card-title>Summary</b-card-title>
                <b-badge :variant="`light-${getBookingTypeVariantAndName(booking.bookingType).variant}`">
                    {{ getBookingTypeVariantAndName(booking.bookingType).name }}
                </b-badge>
            </div>
        </b-card-header>

        <b-card-body class="pb-0">
            <b-col class="px-0">
                <div
                    class="d-flex justify-content-between align-items-center"
                    v-for="{ taxi, taxiRate } in booking.bookingTaxis"
                >
                    <template v-if="taxi !== null">
                        <div class="mb-25">
                            <h6 class="mb-0">
                                {{ taxi.firstName }} {{ taxi.lastName }}
                            </h6>
                            <span class="text-muted">{{ taxi.licenceNum }}</span>
                        </div>
                        <p class="mb-25">{{ formatCurrency(taxiRate) }}</p>
                    </template>
                </div>
            </b-col>
        </b-card-body>

        <hr class="invoice-spacing" />

        <b-card-body class="py-0">
            <b-col class="px-0">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="mb-25">
                        <h6 class="mb-0">GTA Fee</h6>
                    </div>
                    <p class="mb-25">{{ formatCurrency(booking.officeFee) }}</p>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <div class="mb-25">
                        <h6 class="mb-0 text-muted">Baggage Fee</h6>
                    </div>
                    <p class="mb-25 text-muted">{{ formatCurrency(booking.heavyBaggageKgTotalFee) }}</p>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <div class="mb-25">
                        <h6 class="mb-0 text-muted">Meter Reader Fee</h6>
                    </div>
                    <p class="mb-25 text-muted">{{ formatCurrency(booking.meterReaderTotalFee) }}</p>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <div class="mb-25">
                        <h6 class="mb-0 text-muted">Waiting Time Fee</h6>
                    </div>
                    <p class="mb-25 text-muted">{{ formatCurrency(booking.waitingTimeTotalFee) }}</p>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <div class="mb-25">
                        <h6 class="mb-0 text-muted">Wheelchair Fee</h6>
                    </div>
                    <p class="mb-25 text-muted">{{ formatCurrency(booking.wheelchairTotalFee) }}</p>
                </div>
            </b-col>
        </b-card-body>

        <hr class="invoice-spacing" />

        <b-card-body class="py-0">
            <b-col>
                <b-row
                    v-for="bookingFeeType in bookingFeeTypeList"
                    :key="bookingFeeType.id"
                    class="justify-content-between align-items-center"
                >
                    <!-- If bokking fee is default and is admin fee but the company don't pay admin fee uncheck -->
                    <b-form-checkbox
                        :checked="booking.bookingAdditionalFees.find(
                                (fee) =>
                                    fee.bookingFeeTypeId === bookingFeeType.id
                            )
                                ? bookingFeeType.id
                                : null
                            "
                        :value="bookingFeeType.id"
                        class="mb-25 font-weight-bolder"
                        @change="additionalFeeChange($event, bookingFeeType)"
                    >
                        {{ bookingFeeType.name }}
                    </b-form-checkbox>
                    <p class="mb-25">
                        {{ formatCurrency(getBookingFeeTypeAmount(bookingFeeType)) }}
                    </p>
                </b-row>
                <b-row class="justify-content-end align-items-center my-2">
                    <h4 class="pr-25">TOTAL</h4>
                    <h4 class="pl-25">
                        {{ formatCurrency(booking.totalBookingFee) }}
                    </h4>
                </b-row>
            </b-col>
        </b-card-body>

        <b-button
            block
            variant="primary"
            @click="$emit('submitBooking')"
        >
            {{ booking.id ? 'Edit' : 'Add' }} Booking
        </b-button>
    </b-card>
</template>

<script>
import {
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCol,
    BFormCheckbox,
    BRow,
} from 'bootstrap-vue';
import { computed, watch } from '@vue/composition-api';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import store from '@/store';
import useBookingUtils from '../composables/useBookingUtils';
import useUtils from '@/composables/useUtils';
import vSelect from 'vue-select';

export default {
    setup() {
        const { formatCurrency, formatDecimalNumber } = useUtils();
        const {
            getOfficeRate,
            getDriverRate,
            updateTaxiRate,
            getBookingTypeVariantAndName,
            storeBooking,
        } = useBookingUtils();

        const bookingFeeTypeList =
            store.getters['bookingFeeType/bookingFeeTypeList'];
        const companyList = store.getters['company/companyList'];

        const booking = computed(() => store.getters['booking/booking']);

        const getBaggageNumber = computed(
            () => store.getters['booking/booking'].baggageNum
        );
        const getCompany = computed(
            () => store.getters['booking/booking'].companyId
        );

        watch(getBaggageNumber, (baggages) => {
            const { baggageFeePerBagGtaoffice, paxFeeGtaoffice } =
                store.getters['gibAirRate/gibAirRate'];
            const result =
                paxFeeGtaoffice * booking.value.passengerNum +
                baggageFeePerBagGtaoffice * baggages;
            booking.value.officeFee = formatDecimalNumber(result);
        });

        watch(getCompany, (companyId) => {
            const company = companyList.find((company) => company.id === companyId);

            if (companyId && !company.paysAdminFee) removeAdminFeesAdded();
            else addDefaultAdminFees();
        });

        const addDefaultBookingFee = () => {
            const bookingAdditionalFees = bookingFeeTypeList
            .filter((fee) => fee.addByDefault)
            .map(({ id, feeAmount, name, chargeToCompany }) => ({
                    bookingFeeTypeId: id,
                    gtaFeeAmount: chargeToCompany ? feeAmount : 0,
                    taxiFeeAmount: chargeToCompany ? 0 : feeAmount,
                    bookingFeeTypeName: name,
                })
            );
            
            const defaultBookingFeeAmountToGta = bookingAdditionalFees.reduce(
                (total, fee) => parseFloat(total) + parseFloat(fee.gtaFeeAmount),
                0
            );
                
            const defaultBookingFeeAmountToTaxi = bookingAdditionalFees.reduce(
                (total, fee) => parseFloat(total) + parseFloat(fee.taxiFeeAmount),
                0
            );
                    
            booking.value.bookingAdditionalFees = bookingAdditionalFees
            booking.value.bookingAdditionalFeesTotalAmountToGta = defaultBookingFeeAmountToGta;
            booking.value.bookingAdditionalFeesPerTaxi = defaultBookingFeeAmountToTaxi;

            booking.value.totalBookingFee = defaultBookingFeeAmountToGta;
            booking.value.officeFee = getOfficeRate(booking.value);
            
            const driverRate = getDriverRate(booking.value);
            booking.value.bookingTaxis = updateTaxiRate(booking.value, driverRate);

            storeBooking(booking.value);
        };

        const additionalFeeChange = (event, fee) => {
            let additionalFeeToGta = 0;
            let additionalFeeToTaxi = 0;
            let bookingFee = 0;

            if (!event) {
                additionalFeeToGta = fee.chargeToCompany 
                ? (booking.value.bookingAdditionalFeesTotalAmountToGta * 100 - fee.feeAmount * 100) / 100
                : booking.value.bookingAdditionalFeesTotalAmountToGta
                
                bookingFee = fee.chargeToCompany 
                ? (booking.value.totalBookingFee * 100 - fee.feeAmount * 100) / 100 
                : booking.value.totalBookingFee;

                additionalFeeToTaxi = !fee.chargeToCompany
                ? (booking.value.bookingAdditionalFeesPerTaxi * 100 - fee.feeAmount * 100) / 100 
                : booking.value.bookingAdditionalFeesPerTaxi

                booking.value.bookingAdditionalFees = booking.value.bookingAdditionalFees
                .filter((additionalFee) => additionalFee.bookingFeeTypeId !== fee.id);
            } else {
                additionalFeeToGta = fee.chargeToCompany 
                ? (booking.value.bookingAdditionalFeesTotalAmountToGta * 100 + fee.feeAmount * 100) / 100 
                : booking.value.bookingAdditionalFeesTotalAmountToGta;
                
                bookingFee = fee.chargeToCompany 
                ? (booking.value.totalBookingFee * 100 + fee.feeAmount * 100) / 100 
                : booking.value.totalBookingFee;

                additionalFeeToTaxi = !fee.chargeToCompany
                ? (booking.value.bookingAdditionalFeesPerTaxi * 100 + fee.feeAmount * 100) / 100 
                : booking.value.bookingAdditionalFeesPerTaxi;

                booking.value.bookingAdditionalFees.push({
                    bookingFeeTypeId: fee.id,
                    gtaFeeAmount: fee.chargeToCompany ? fee.feeAmount : 0,
                    taxiFeeAmount: fee.chargeToCompany ? 0 : fee.feeAmount,
                    bookingFeeTypeName: fee.name,
                });
            }
            
            booking.value.bookingAdditionalFeesTotalAmountToGta = additionalFeeToGta;
            booking.value.bookingAdditionalFeesPerTaxi = additionalFeeToTaxi;
            booking.value.totalBookingFee = bookingFee;
            booking.value.officeFee = getOfficeRate(booking.value);

            const driverRate = getDriverRate(booking.value);
            booking.value.bookingTaxis = updateTaxiRate(booking.value, driverRate);

            storeBooking(booking.value);
        };

        const removeAdminFeesAdded = () => {
            const adminFeesAdded = bookingFeeTypeList.filter(
                (fee) =>
                    fee.isAdminFee &&
                    booking.value.bookingAdditionalFees.find(
                        (additionalFee) =>
                            additionalFee.bookingFeeTypeId === fee.id
                    )
            );

            const adminFeeAmount = adminFeesAdded.reduce(
                (total, fee) => parseFloat(total) + parseFloat(fee.feeAmount),
                0
            );

            // Remove admin fees added from booking
            const notAdminFees = bookingFeeTypeList.filter(
                (fee) =>
                    !fee.isAdminFee &&
                    booking.value.bookingAdditionalFees.find(
                        (additionalFee) =>
                            additionalFee.bookingFeeTypeId === fee.id
                    )
            );

            booking.value.bookingAdditionalFees = notAdminFees.map(
                ({ id, feeAmount, name }) => ({
                    bookingFeeTypeId: id,
                    gtaFeeAmount: feeAmount,
                    taxiFeeAmount: feeAmount,
                    bookingFeeTypeName: name,
                })
            );
            booking.value.bookingAdditionalFeesTotalAmountToGta -= adminFeeAmount;
            booking.value.totalBookingFee =
                booking.value.totalBookingFee - adminFeeAmount;
            booking.value.officeFee = getOfficeRate(booking.value);
            storeBooking(booking.value);
        };

        const addDefaultAdminFees = () => {
            const defaultAdminFees = bookingFeeTypeList.filter(
                (fee) =>
                    fee.isAdminFee &&
                    !booking.value.bookingAdditionalFees.find(
                        (additionalFee) =>
                            additionalFee.bookingFeeTypeId === fee.id
                    )
            );
            if (!defaultAdminFees.length) return;

            const adminFeeAmount = defaultAdminFees.reduce(
                (total, fee) => parseFloat(total) + parseFloat(fee.feeAmount),
                0
            );

            const adminFeesFormated = defaultAdminFees.map(
                ({ id, feeAmount, name }) => ({
                    bookingFeeTypeId: id,
                    gtaFeeAmount: feeAmount,
                    taxiFeeAmount: 0,
                    bookingFeeTypeName: name,
                })
            );

            booking.value.bookingAdditionalFees = [
                ...booking.value.bookingAdditionalFees,
                ...adminFeesFormated,
            ];
            booking.value.bookingAdditionalFeesTotalAmountToGta += adminFeeAmount;
            booking.value.totalBookingFee =
                booking.value.totalBookingFee + adminFeeAmount;
            booking.value.officeFee = getOfficeRate(booking.value);
            storeBooking(booking.value);
        };

        const getBookingFeeTypeAmount = (feeType) => {
            const bookingFeeTypeAdded =
                booking.value.bookingAdditionalFees.find(
                    (fee) => fee.bookingFeeTypeId === feeType.id
                );

            return bookingFeeTypeAdded
                ? formatDecimalNumber(bookingFeeTypeAdded.gtaFeeAmount > 0 ? bookingFeeTypeAdded.gtaFeeAmount : bookingFeeTypeAdded.taxiFeeAmount)
                : formatDecimalNumber(feeType.feeAmount);
        };

        if (booking.value.id === null) {
            addDefaultBookingFee();
            
            if(!booking.value.bookingType.isAirportPickUp && !booking.value.bookingType.isGibAir )
                addDefaultAdminFees()
        }

        return {
            // Reactive
            booking,
            bookingFeeTypeList,

            //Method
            additionalFeeChange,
            formatCurrency,
            getBookingFeeTypeAmount,
            getBookingTypeVariantAndName,
        };
    },
    components: {
        BBadge,
        BButton,
        BCard,
        BCardBody,
        BCardHeader,
        BCardTitle,
        BCol,
        BFormCheckbox,
        BRow,
        ValidationObserver,
        ValidationProvider,
        vSelect,
    },
};
</script>
