<template>
  <div class="d-flex flex-md-row flex-column">
    <b-col md="6">
      <b-form-group
        label="Cruise Vessel"
        label-for="cruise-vessel"
      >
        <b-form-input
          @input="updateBooking"
          id="cruise-vessel"
          v-model="booking.cruiseLinerVessel"
        />
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group
        label="Crew Members"
        label-for="crew-members"
        >
        <b-form-textarea
        @input="updateBooking"
        v-model="booking.crewMembers"
        id="crew-members"
        />
      </b-form-group>
    </b-col>
  </div>
</template>

<script>
import { BBadge, BCol, BFormGroup, BFormInput, BFormTextarea } from 'bootstrap-vue';
import { computed } from '@vue/composition-api';
import store from '@/store';
import useBookingUtils from '@/modules/booking/composables/useBookingUtils';
import vSelect from 'vue-select';

export default {
  setup() {
    const { storeBooking } = useBookingUtils();

    const booking = computed(() => store.getters['booking/booking']);

    const updateBooking = () => {
      storeBooking(booking.value);
    };

    return {
      booking,

      //Method
      updateBooking,
    };
  },
  components: {
    BBadge,
    BCol,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    vSelect,
  },
};
</script>
