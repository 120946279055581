<template>
  <section
    class="invoice-add-wrapper"
    v-if="bookingData.bookingTypeId !== null"
  >
    <b-row class="invoice-add" v-if="!bookingData.bookingType.isCancelled">
      <b-col lg="8">
        <validation-observer ref="bookingForm">
          <b-form @submit.prevent>
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <b-card-body class="invoice-padding pb-0">
                <booking-voucher-date-source />

                <b-row>
                  <b-col
                    md="6"
                    order="1"
                    order-md="2"
                  >
                    <booking-staff v-if="!bookingType.isGibAir" />
                  </b-col>

                  <b-col
                    md="6"
                    order="2"
                    order-md="1"
                  >
                    <booking-company-contact />
                  </b-col>
                </b-row>

                <booking-cruise v-if="bookingType.isCrew || bookingType.isPax" />
              </b-card-body>

              <hr class="invoice-spacing" />

              <b-card-body class="invoice-padding pt-0">

                <b-row class="justify-content-end align-items-center">
                  <b-form-checkbox
                    @change="enforcePaxRateChange"
                    v-if="bookingType.isPax"
                    v-model="bookingData.enforcePaxRate"
                    name="has-company"
                    switch
                    inline
                    class="ml-1"
                  >
                    Apply PAX Rate
                  </b-form-checkbox>
                </b-row>

                <div class="d-flex flex-md-row flex-column justify-content-between pt-50">
                  <!-- Pickup / Way point / Dropoff -->
                  <booking-way-point />

                  <b-col md="6">
                    <!-- Approved By / Patient Name -->
                    <booking-approval-patient v-if="bookingType.isGha" />

                    <!-- Passengers -->
                    <booking-passenger v-else />

                    <!-- Baggage Number -->
                    <booking-baggage v-if="bookingType.isGibAir" />
                  </b-col>
                </div>
              </b-card-body>

              <b-card-body class="invoice-padding form-item-section">
                <!-- Taxi & Vehicle-->
                <booking-taxi />
              </b-card-body>

              <b-card-body class="invoice-padding">
                <!-- Notes -->
                <booking-note />
              </b-card-body>

            </b-card>
          </b-form>
        </validation-observer>
      </b-col>

      <b-col lg="4">
        <booking-summary-card @submitBooking="createNewBooking" />

        <booking-adjust-fee-field />
      </b-col>
    </b-row>
    <b-row class="invoice-add" v-else>
      <b-col lg="8">
        <validation-observer ref="bookingForm">
          <b-form @submit.prevent>
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <b-card-body class="invoice-padding pb-0">
                <booking-voucher-date-source />
              </b-card-body>

              <b-card-body class="invoice-padding">
                <booking-note />
              </b-card-body>

            </b-card>
          </b-form>
        </validation-observer>
      </b-col>

      <b-col lg="4">
        <booking-summary-card @submitBooking="createNewBooking" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCol,
  BForm,
  BFormCheckbox,
  BRow,
} from 'bootstrap-vue';
import { computed, ref } from '@vue/composition-api';
import { required } from '@validations';
import { ValidationObserver } from 'vee-validate';
import BookingAdjustFeeField from '@/modules/booking/components/BookingAdjustFee.vue';
import BookingApprovalPatient from '../components/BookingApprovalPatient.vue';
import BookingBaggage from '@/modules/booking/components/BookingBaggage.vue';
import BookingCompanyContact from '@/modules/booking/components/BookingCompanyContact.vue';
import BookingCruise from '@/modules/booking/components/BookingCruise.vue';
import BookingNote from '@/modules/booking/components/BookingNote.vue';
import BookingPassenger from '@/modules/booking/components/BookingPassenger.vue';
import BookingStaff from '@/modules/booking/components/BookingStaff.vue';
import BookingSummaryCard from '@/modules/booking/components/BookingSummaryCard.vue';
import BookingTaxi from '@/modules/booking/components/BookingTaxi.vue';
import BookingVoucherDateSource from '@/modules/booking/components/BookingVoucherDateSource.vue';
import BookingWayPoint from '@/modules/booking/components/BookingWayPoint.vue';
import router from '@/router';
import store from '@/store';
import Swal from 'sweetalert2';
import useBooking from '@/modules/booking/composables/useBooking';
import useBookingFeeType from '@/modules/booking-settings/composables/useBookingFeeType';
import useBookingSourceType from '@/modules/booking/composables/useBookingSourceType';
import useBookingUtils from '@/modules/booking/composables/useBookingUtils';
import useCompany from '@/modules/company/composables/useCompany';
import useCrewRate from '@/modules/rate/composables/useCrewRate';
import useGibAirRate from '@/modules/rate/composables/useGibAirRate';
import useGlobalSettings from '@/modules/setting/composables/useGlobalSettings';
import usePoi from '@/modules/poi/composables/usePoi';
import useStaff from '@/modules/staff/composables/useStaff';
import useTaxi from '@/modules/taxi/composables/useTaxi';
import useUtils from '@/composables/useUtils';
import useWaitTimeRate from '@/modules/rate/composables/useWaitTimeRate';
import useBookingType from '../composables/useBookingType';

export default {
  setup(_, ctx) {
    const { createBooking } = useBooking();
    const { getBookingFeeTypeList } = useBookingFeeType();
    const { getBookingSourceTypeList } = useBookingSourceType();
    const { getCrewRates } = useCrewRate();
    const { getGlobalSetting } = useGlobalSettings();
    const { getCompanyList } = useCompany();
    const { getList: getPointOfInterestList } = usePoi();
    const { getTaxiList } = useTaxi();
    const { getListStaff } = useStaff();
    const { getWaitTimeRates } = useWaitTimeRate();
    const { getGibAirRate } = useGibAirRate();
    const { convertDateToUTC } = useUtils();
    const { getBookingTypeList } = useBookingType();
    const {
      getBookingRate,
      getTotalBookingFee,
      getOfficeRate,
      storeBooking,
      validateCrewBooking
    } = useBookingUtils();

    const BOOKING_TYPE_FROM_URL = ctx.attrs.bookingType;
    const bookingData = computed(() => store.getters['booking/booking']);
    const companyList = computed(() => store.getters['company/companyList']);
    const bookingForm = ref(null);
    const bookingType = ref(null);
    const bookingTypeList = ref([])
    const globalSettings = ref(null);

    const createNewBooking = () => {
      const booking = store.getters['booking/booking'];

      bookingForm.value.validate().then(async (valid) => {
        if (!valid) return;
        if (bookingType.value.isCrew) {
          const result = validateCrewBooking(booking, globalSettings.value);
          if (result !== null) {
            const { value } = await Swal.fire({
              title: result,
              text: 'Do you still want to continue?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes, continue!',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            });

            if (!value) return;
          }
        }

        const result = await createBooking({
          ...booking,
          bookingTaxis: booking.bookingType.isCancelled ? [] :  booking.bookingTaxis,
          bookedForDateUtc: convertDateToUTC(booking.bookedForDateUtc),
        });

        if (result === null) return;

        router.push({
          name: 'booking-detail',
          params: { id: result.id },
        });
      });
    };

    const enforcePaxRateChange = (enforcePaxRate) => {
      const bookingRate = getBookingRate(bookingData.value);

      bookingData.value.totalBookingFee = getTotalBookingFee(bookingData.value, bookingRate);
      bookingData.value.officeFee = getOfficeRate(bookingData.value);
      storeBooking({
        enforcePaxRate,
        totalBookingFee: bookingData.value.totalBookingFee,
        officeFee: bookingData.value.officeFee,
      });
    };

    const initBookingObj = () => {
      if (BOOKING_TYPE_FROM_URL === 'crew') {
        bookingType.value = bookingTypeList.value.find(bt => bt.isCrew)
        
        return { bookingType:bookingType.value, bookingTypeId: bookingType.value.id };
      }

      if (BOOKING_TYPE_FROM_URL === 'gha') {
        bookingType.value = bookingTypeList.value.find(bt => bt.isGha)
        
        return { bookingType:bookingType.value, bookingTypeId: bookingType.value.id };
      }
      if (BOOKING_TYPE_FROM_URL === 'gib-air') {
        bookingType.value = bookingTypeList.value.find(bt => bt.isGibAir)
        
        return { bookingType:bookingType.value, bookingTypeId: bookingType.value.id };
      }
      if (BOOKING_TYPE_FROM_URL === 'pax') {
        bookingType.value = bookingTypeList.value.find(bt => bt.isPax)
        
        return { bookingType:bookingType.value, bookingTypeId: bookingType.value.id, enforcePaxRate: true };
      }
      if (BOOKING_TYPE_FROM_URL === 'airport-pick-up') {
        bookingType.value = bookingTypeList.value.find(bt => bt.isAirportPickUp)
        
        return { bookingType:bookingType.value, bookingTypeId: bookingType.value.id };
      }
      if (BOOKING_TYPE_FROM_URL === 'cancelled') {
        bookingType.value = bookingTypeList.value.find(bt => bt.isCancelled)
        
        return { 
          bookingType:bookingType.value,
          bookingTypeId: bookingType.value.id,
          companyId: companyList.value.find(company => company.isCancelled).id
        };
      }
    };

    const initData = async () => {
      const [globalSettingsData, bookingTypeListData] = await Promise.all([
        getGlobalSetting(),
        getBookingTypeList(),
        getBookingFeeTypeList(),
        getWaitTimeRates(),
        getCrewRates(),
        getCompanyList(),
        getBookingSourceTypeList(),
        getListStaff(),
        getPointOfInterestList(),
        getTaxiList(),
        getGibAirRate(),
      ])
      
      globalSettings.value = globalSettingsData;
      bookingTypeList.value = bookingTypeListData;

      const initBookingData = initBookingObj();
      storeBooking({ ...initBookingData });
    };

    store.commit('booking/resetBooking');
    initData();

    return {
      // Reactive
      bookingData,
      bookingForm,
      bookingType,

      // Method
      createNewBooking,
      enforcePaxRateChange,
 
      // Validator
      required,
    };
  },
  components: {
    BCard,
    BCardBody,
    BCol,
    BForm,
    BFormCheckbox,
    BRow,
    BookingAdjustFeeField,
    BookingApprovalPatient,
    BookingBaggage,
    BookingCompanyContact,
    BookingCruise,
    BookingNote,
    BookingPassenger,
    BookingStaff,
    BookingSummaryCard,
    BookingTaxi,
    BookingVoucherDateSource,
    BookingWayPoint,
    ValidationObserver,
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.taxi-vehicle-select .vs__selected {
  width: 95%;
}

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '~@core/scss/base/components/variables-dark';

.booking-header {
  .title {
    width: 115px;
  }
}

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {

  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
