import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useBookingSourceType = () => {
  const toast = useToast();

  const getBookingSourceTypeList = async () => {
    try {
      return await store.dispatch('bookingSourceType/getList');
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error getting the booking source type list',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  return {
    // Method
    getBookingSourceTypeList,
  };
};

export default useBookingSourceType;
