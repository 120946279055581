<template>
  <section class="pl-1">
      <b-form-group
        label="Company"
        label-for="company"
      >
        <v-select
          :clearable="true"
          :options="companyList"
          :reduce="(company) => company.id"
          @input="storeBookingData"
          input-id="company"
          label="name"
          v-model="booking.companyId"
        />
      </b-form-group>

      <b-form-group
        label="Contact"
        label-for="contact"
      >
        <b-form-input
          @input="storeBookingData"
          input-id="contact"
          v-model="booking.companyContact"
        />
      </b-form-group>
  </section>
</template>

<script>
import { BCol, BFormGroup, BFormInput } from 'bootstrap-vue';
import { computed } from '@vue/composition-api';
import store from '@/store';
import useBookingUtils from '@/modules/booking/composables/useBookingUtils';
import vSelect from 'vue-select';

export default {
  setup() {
    const { storeBooking } = useBookingUtils();

    const companyList = computed(() => store.getters['company/companyList']);

    const booking = computed(() => store.getters['booking/booking']);

    const storeBookingData = () => {
      storeBooking(booking.value);
    };

    const preSelectCompany = () => {
      if (booking.value.bookingType.isGha) {
        booking.value.companyId = companyList.value.find(company => company.isGha).id
      }

      if (booking.value.bookingType.isGibAir) {
        booking.value.companyId = companyList.value.find(company => company.isGibAir).id
      }
    }

    if (booking.value.id === null) {
      preSelectCompany();
    }

    return {
      booking,
      companyList,

      //Method
      storeBookingData,
    };
  },
  components: {
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
  },
};
</script>
