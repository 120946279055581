<template>
  <validation-provider
    name="Baggages"
    #default="{ errors }"
    rules="required"
  >
    <b-form-group
      label="Baggages"
      label-for="baggage-num"
      type="number"
    >
      <b-form-input
        @input="calculateTotalBookingFee"
        id="baggage-num"
        min="1"
        type="number"
        v-model="booking.baggageNum"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue';
import { computed } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import store from '@/store';
import useBookingUtils from '@/modules/booking/composables/useBookingUtils';

export default {
  setup() {
    const {
      getBookingRate,
      getDriverRate,
      getTotalBookingFee,
      storeBooking,
      updateTaxiRate
    } = useBookingUtils();

    const booking = computed(() => store.getters['booking/booking']);

    const calculateTotalBookingFee = (baggages) => {
      booking.value.baggagesNum = +baggages;

      const bookingRate = getBookingRate(booking.value);
      const driverRate = getDriverRate(booking.value);

      booking.value.totalBookingFee = getTotalBookingFee(booking.value, bookingRate);
      booking.value.bookingTaxis = updateTaxiRate(booking.value, driverRate);

      storeBooking(booking.value);
    };

    return {
      // Reactive
      booking,

      //Method
      calculateTotalBookingFee,
    };
  },
  components: {
    BFormGroup,
    BFormInput,
    ValidationProvider,
  },
};
</script>
