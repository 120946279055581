<template>
  <b-form-group
    label="Adjust Booking Fee"
    label-for="adjust-booking-fee"
    description="Press ENTER to apply. Input 0 to reset."
  >
    <b-form-input
      @keyup.enter="adjustBokingFee($event.target.value)"
      id="adjust-booking-fee"
      type="number"
      v-model="bookingAdjustmentFee"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue';
import { computed, ref } from '@vue/composition-api';
import store from '@/store';
import useBookingUtils from '@/modules/booking/composables/useBookingUtils';
import useUtils from '@/composables/useUtils';

export default {
  setup() {
    const { getBookingRate, getTotalBookingFee, getDriverRate, updateTaxiRate, storeBooking } = useBookingUtils();
    const { formatDecimalNumber } = useUtils();

    const booking = computed(() => store.getters['booking/booking']);
    const bookingAdjustmentFee = ref(store.getters['booking/booking'].bookingAdjustmentFee);

    const adjustBokingFee = (value) => {
      const amount = parseFloat(value);
      if (!value || Number.isNaN(value)) return;

      booking.value.bookingAdjustmentFee = amount;

      const bookingRate = getBookingRate(booking.value);
      const driverRate = getDriverRate(booking.value);

      booking.value.totalBookingFee = getTotalBookingFee(booking.value, bookingRate);
      booking.value.bookingTaxis = updateTaxiRate(booking.value, driverRate);

      storeBooking(booking.value);
    };

    return {
      // Reactive
      booking,
      bookingAdjustmentFee,

      // Method
      adjustBokingFee
    };
  },
  components: {
    BFormGroup,
    BFormInput,
  },
};
</script>
