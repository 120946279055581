var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"pl-1"},[_c('validation-provider',{attrs:{"name":"Issued by","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Issued by","label-for":"issued-staff"}},[_c('v-select',{attrs:{"clearable":false,"options":_vm.staffList,"reduce":function (staff) { return staff.id; },"input-id":"issued-staff","label":"firstname"},on:{"input":_vm.storeBookingData},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var firstname = ref.firstname;
var lastname = ref.lastname;
return [_c('span',[_vm._v(" "+_vm._s((firstname + " " + lastname)))])]}},{key:"selected-option",fn:function(ref){
var firstname = ref.firstname;
var lastname = ref.lastname;
return [_c('span',[_vm._v(" "+_vm._s((firstname + " " + lastname)))])]}}],null,true),model:{value:(_vm.booking.issuedByStaffMemberId),callback:function ($$v) {_vm.$set(_vm.booking, "issuedByStaffMemberId", $$v)},expression:"booking.issuedByStaffMemberId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Dispatched by","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Dispatched by","label-for":"dispatched-staff"}},[_c('v-select',{attrs:{"clearable":false,"options":_vm.staffList,"reduce":function (staff) { return staff.id; },"input-id":"dispatched-staff","label":"firstname"},on:{"input":_vm.storeBookingData},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var firstname = ref.firstname;
var lastname = ref.lastname;
return [_c('span',[_vm._v(" "+_vm._s((firstname + " " + lastname)))])]}},{key:"selected-option",fn:function(ref){
var firstname = ref.firstname;
var lastname = ref.lastname;
return [_c('span',[_vm._v(" "+_vm._s((firstname + " " + lastname)))])]}}],null,true),model:{value:(_vm.booking.dispatchedByStaffMemberId),callback:function ($$v) {_vm.$set(_vm.booking, "dispatchedByStaffMemberId", $$v)},expression:"booking.dispatchedByStaffMemberId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }