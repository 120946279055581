<template>
  <div>
    <validation-provider
      #default="{ errors }"
      name="Approved by"
      rules="required"
    >
      <b-form-group
        label="Approved by"
        label-for="approved-by"
      >
        <b-form-input
          @input="addApprovalNameOrPatienName"
          id="approved-by"
          v-model="booking.ghaApprovalName"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>

    <validation-provider
      #default="{ errors }"
      name="Patient Name"
      rules="required"
    >
      <b-form-group
        label="Patient Name"
        label-for="patient-name"
      >
        <b-form-input
          @input="addApprovalNameOrPatienName"
          id="patient-name"
          v-model="booking.ghaPatientName"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>
  </div>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue';
import { computed } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import store from '@/store';
import useBookingUtils from '@/modules/booking/composables/useBookingUtils';

export default {
  setup() {
    const { storeBooking } = useBookingUtils();

    const booking = computed(() => store.getters['booking/booking']);

    const addApprovalNameOrPatienName = () => {
      booking.value.passengerNum = 1;
      storeBooking(booking.value);
    };

    return {
      // Reactive
      booking,

      //Method
      addApprovalNameOrPatienName,
    };
  },
  components: {
    BFormGroup,
    BFormInput,
    ValidationProvider,
  },
};
</script>
