<template>
  <div>
    <div
      ref="taxiFormRef"
      class="repeater-form"
      :style="{ height: trHeight }"
    >
      <b-row
        v-for="(bookingTaxi, index) in booking.bookingTaxis"
        :key="index"
        ref="taxiItemForm"
        class="pb-2"
      >
        <b-col cols="12">
          <div class="d-flex border rounded">
            <b-row class="flex-grow-1 p-2">
              <b-col lg="6">
                <validation-provider
                  #default="{ errors }"
                  name="Taxi"
                  rules="required"
                >
                  <b-form-group
                    label="Taxi"
                    label-for="taxi"
                  >
                    <v-select
                      :clearable="false"
                      :options="taxiList"
                      @input="(taxi) => taxiSelected(index, taxi)"
                      label="licenceNum"
                      v-model="bookingTaxi.taxi"
                    >
                      <template #option="{ firstName, lastName, licenceNum }">
                        <span>
                          <span class="font-weight-bolder">{{
                            licenceNum
                          }}</span>
                          -
                          <small>{{ `${firstName} ${lastName}` }}</small>
                        </span>
                      </template>

                      <template
                        #selected-option="{ firstName, lastName, licenceNum }"
                      >
                        <span>
                          <span class="font-weight-bolder">{{
                            licenceNum
                          }}</span>
                          -
                          <small>{{ `${firstName} ${lastName}` }}</small>
                        </span>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col lg="6">
                <b-form-group
                  label="Vehicle"
                  label-for="vehicle"
                >
                  <v-select
                    :clearable="false"
                    :options="taxiVehiclesList[index]"
                    @input="(vehicle) => vehicleSelected(index, vehicle)"
                    label="model"
                    v-model="bookingTaxi.vehicle"
                  >
                    <template #no-options>Please, select the taxi</template>
                    <template #option="{ model, regNum }">
                      <span>
                        <span class="font-weight-bolder">{{ regNum }}</span>
                        -
                        <small>{{ model }}</small>
                      </span>
                    </template>

                    <template #selected-option="{ model, regNum }">
                      <span>
                        <span class="font-weight-bolder">{{ regNum }}</span>
                        -
                        <small>{{ model }}</small>
                      </span>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <b-form-group
                  label="Waiting Time (min)"
                  label-for="waiting-time"
                >
                  <v-select
                    :options="waitTimeRateList"
                    @input="(waitTime) => waitingTimeSelected(index, waitTime)"
                    id="waiting-time"
                    label="timeInMin"
                    v-model="bookingTaxi.waitingTimeInMin"
                  >
                    <template #option="{ timeInMin, fee }">
                      <span>
                        <span class="font-weight-bolder"
                          >{{ timeInMin }} min</span
                        >
                        - £{{ fee }}
                      </span>
                    </template>

                    <template #selected-option="{ timeInMin }">
                      <span>{{ timeInMin }} min</span>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <validation-provider
                  v-if="
                    booking.bookingWaypointPois.length > 0 ||
                    !booking.bookingType.isCrew
                  "
                  name="Meter Reader"
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label="Meter Reader (£)"
                    label-for="meter-reader"
                  >
                    <b-form-input
                      @input="(meterReader) => applyMeterReader(index, meterReader), "
                      id="meater-reader"
                      min="1"
                      type="number"
                      v-model="bookingTaxi.meterReaderFee"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col lg="6">
                <b-form-group
                  label="Wheelchairs"
                  label-for="wheelchair-num"
                >
                  <b-form-input
                    @input="(wheelchair) => applyWheelchair(index, wheelchair)"
                    id="wheelchair-num"
                    type="number"
                    min="0"
                    v-model="bookingTaxi.wheelchairNum"
                  />
                </b-form-group>
              </b-col>

              <b-col
                lg="6"
                v-if="
                  !booking.bookingType.isGha && !booking.bookingType.isGibAir
                "
              >
                <validation-provider
                  name="Meter Reader"
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label="Baggage (kg)"
                    label-for="baggage"
                  >
                    <b-form-input
                      @input="(baggageKg) => applyBaggageKg(index, baggageKg), "
                      id="baggage"
                      min="1"
                      type="number"
                      v-model="bookingTaxi.heavyBaggageKg"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <div
              class="pt-50 pr-50"
              v-if="booking.bookingTaxis.length > 1"
            >
              <feather-icon
                size="16"
                icon="XIcon"
                class="cursor-pointer"
                @click="removeTaxi(index)"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-button
      size="sm"
      variant="primary"
      @click="addNewTaxi"
    >
      Add Taxi
    </b-button>
  </div>
</template>
<script>
import { BButton, BCol, BFormGroup, BFormInput, BRow } from 'bootstrap-vue';
import {
  computed,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
} from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import store from '@/store';
import useBookingUtils from '@/modules/booking/composables/useBookingUtils';
import useTransition from '@/composables/useTransition';
import vSelect from 'vue-select';

export default {
  setup() {
    const { trAddHeight, trHeight, trSetHeight, trTrimHeight } =
      useTransition();
    const {
      getBookingRate,
      getDriverRate,
      getOfficeRate,
      getTotalBookingFee,
      storeBooking,
      updateTaxiRate,
    } = useBookingUtils();

    const taxiList = store.getters['taxi/taxiList'];
    const waitTimeRateList = store.getters['waitTimeRate/waitTimeRateList'];

    const booking = computed(() => store.getters['booking/booking']);

    const taxiFormRef = ref(null);
    const taxiItemForm = ref(null);
    const taxiVehiclesList = ref([]);

    onMounted(() => {
      initTrHeight();
    });
    onUnmounted(() => {
      window.removeEventListener('resize', initTrHeight);
    });
    window.addEventListener('resize', initTrHeight);

    const initTrHeight = () => {
      trSetHeight(null);
      nextTick(() => {
        trSetHeight(taxiFormRef.value.scrollHeight);
      });
    };

    const addNewTaxi = () => {
      taxiFormRef.value.style.overflow = 'hidden';
      booking.value.bookingTaxis.push({
        taxi: null,
        taxiRate: 0.0,
        vehicle: null,
        waitingTimeFee: 0.0,
        waitingTimeInMin: null,
        meterReaderFee: 0,
        heavyBaggageKg: 0,
        wheelchairNum: 0,
      });
      storeBooking(booking.value);

      nextTick(() => {
        trAddHeight(taxiItemForm.value[0].offsetHeight);
        setTimeout(() => {
          taxiFormRef.value.style.overflow = null;
        }, 350);
      });
    };

    const removeTaxi = (index) => {
      booking.value.bookingTaxis.splice(index, 1);

      const driverRate = getDriverRate(booking.value);
      booking.value.bookingTaxis = updateTaxiRate(booking.value, driverRate);
      booking.value.officeFee = getOfficeRate(booking.value);

      storeBooking(booking.value);
      trTrimHeight(taxiItemForm.value[0].offsetHeight);
    };

    const taxiSelected = (index, taxi) => {
      if (taxi !== null) {
        const mainVehicle = taxi.vehicles.find(
          (vehicle) => vehicle.isMainVehicle
        );
        const vehicle = mainVehicle ? mainVehicle : taxi.vehicles[0];
        const driverRate = getDriverRate(booking.value);

        taxiVehiclesList.value[index] = [...taxi.vehicles];

        booking.value.bookingTaxis[index].taxi = taxi;
        booking.value.bookingTaxis[index].vehicle = vehicle;
        booking.value.bookingTaxis = updateTaxiRate(booking.value, driverRate);
        booking.value.officeFee = getOfficeRate(booking.value);
      } else {
        booking.value.bookingTaxis[index].taxi = null;
        booking.value.bookingTaxis[index].vehicle = null;
        booking.value.bookingTaxis[index].taxiRate = 0.0;
      }

      storeBooking(booking.value);
    };

    const vehicleSelected = (index, vehicleSelected) => {
      booking.value.bookingTaxis[index].vehicle.id = vehicleSelected.id;
      storeBooking(booking.value);
    };

    const waitingTimeSelected = (index, waitTime) => {
      booking.value.bookingTaxis[index].waitingTimeInMin =
        waitTime !== null ? `${waitTime.timeInMin}` : null;
      booking.value.bookingTaxis[index].waitingTimeFee =
        waitTime !== null ? waitTime.fee : 0;

      booking.value.waitingTimeTotalFee = booking.value.bookingTaxis.reduce(
        (total, taxi) => total + taxi.waitingTimeFee,
        0
      );

      const bookingRate = getBookingRate(booking.value);
      const driverRate = getDriverRate(booking.value);

      booking.value.totalBookingFee = getTotalBookingFee(
        booking.value,
        bookingRate
      );
      booking.value.bookingTaxis = updateTaxiRate(booking.value, driverRate);
      booking.value.officeFee = getOfficeRate(booking.value);

      storeBooking(booking.value);
    };

    const applyMeterReader = (index, meterReaderFee) => {
      booking.value.bookingTaxis[index].meterReaderFee = +meterReaderFee;

      booking.value.meterReaderTotalFee = booking.value.bookingTaxis.reduce(
        (total, taxi) => total + taxi.meterReaderFee,
        0
      );

      const driverRate = getDriverRate(booking.value);
      const bookingRate = getBookingRate(booking.value);

      booking.value.totalBookingFee = getTotalBookingFee(
        booking.value,
        bookingRate
      );
      booking.value.bookingTaxis = updateTaxiRate(booking.value, driverRate);
      booking.value.officeFee = getOfficeRate(booking.value);

      storeBooking(booking.value);
    };

    const applyBaggageKg = (index, weight) => {
      booking.value.bookingTaxis[index].heavyBaggageKg = +weight;

      const totalBaggageKg = booking.value.bookingTaxis.reduce(
        (totalBaggageKg, taxi) => totalBaggageKg + taxi.heavyBaggageKg,
        0
      );
      booking.value.heavyBaggageKgTotalFee =
        totalBaggageKg *
        store.getters['setting/globalSettings'].baggagePerKiloRate;

      const bookingRate = getBookingRate(booking.value);
      const driverRate = getDriverRate(booking.value);

      booking.value.totalBookingFee = getTotalBookingFee(
        booking.value,
        bookingRate
      );
      booking.value.bookingTaxis = updateTaxiRate(booking.value, driverRate);
      booking.value.officeFee = getOfficeRate(booking.value);

      storeBooking(booking.value);
    };

    const applyWheelchair = (index, wheelchairNum) => {
      booking.value.bookingTaxis[index].wheelchairNum = +wheelchairNum;
      booking.value.wheelchairTotalFee =
        +wheelchairNum * store.getters['setting/globalSettings'].wheelchairRate;

      const bookingRate = getBookingRate(booking.value);
      const driverRate = getDriverRate(booking.value);

      booking.value.totalBookingFee = getTotalBookingFee(
        booking.value,
        bookingRate
      );
      booking.value.bookingTaxis = updateTaxiRate(booking.value, driverRate);
      booking.value.officeFee = getOfficeRate(booking.value);

      storeBooking(booking.value);
    };

    return {
      booking,
      taxiFormRef,
      taxiItemForm,
      taxiList,
      taxiVehiclesList,
      waitTimeRateList,
      trHeight,

      //Method
      addNewTaxi,
      removeTaxi,
      taxiSelected,
      vehicleSelected,
      waitingTimeSelected,
      applyMeterReader,
      applyBaggageKg,
      applyWheelchair,
    };
  },
  components: {
    BButton,
    BCol,
    BFormGroup,
    BRow,
    ValidationProvider,
    BFormInput,
    vSelect,
  },
};
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '~@core/scss/base/components/variables-dark';

.booking-header {
  .title {
    width: 115px;
  }
}

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
