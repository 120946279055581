<template>
  <validation-provider
    #default="{ errors }"
    name="Passengers"
    rules="required"
  >
    <b-form-group
      label="Passengers"
      label-for="passenger-num"
      type="number"
    >
      <b-form-input
        @input="calculateTotalBookingFee"
        id="passenger-num"
        min="1"
        type="number"
        v-model="booking.passengerNum"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue';
import { computed } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import store from '@/store';
import useBookingUtils from '@/modules/booking/composables/useBookingUtils';

export default {
  setup() {
    const {
      getBookingRate,
      getDriverRate,
      getTotalBookingFee,
      getOfficeRate,
      storeBooking,
      updateTaxiRate
    } = useBookingUtils();

    const booking = computed(() => store.getters['booking/booking']);

    const calculateTotalBookingFee = (passengers) => {
      booking.value.passengerNum = +passengers;

      const bookingRate = getBookingRate(booking.value);
      const driverRate = getDriverRate(booking.value);

      booking.value.totalBookingFee = getTotalBookingFee(booking.value, bookingRate);
      booking.value.bookingTaxis = updateTaxiRate(booking.value, driverRate);
      booking.value.officeFee = getOfficeRate(booking.value);

      storeBooking(booking.value);
    };

    return {
      // Reactive
      booking,

      //Method
      calculateTotalBookingFee,
    };
  },
  components: {
    BFormGroup,
    BFormInput,
    ValidationProvider,
  },
};
</script>
