<template>
  <b-col md="6">
    <validation-provider
      #default="{ errors }"
      name="Pick Up"
      rules="required"
    >
      <b-form-group
        label="Pick Up"
        label-for="pick-up"
      >
        <v-select
          taggable
          :clearable="false"
          :options="pointOfInterestList"
          :reduce="(poi) => poi.name"
          @input="() => storeBookingData()"
          input-id="pick-up"
          label="name"
          v-model="booking.pickupPoiName"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>

    <b-form-group
      label="Way Points"
      label-for="way-points"
    >
      <v-select
        taggable
        :options="pointOfInterestList"
        :reduce="(poi) => poi.hasOwnProperty('name') ?  poi.name : poi"
        @input="() => includeWayPoint()"
        input-id="way-points"
        label="name"
        multiple
        v-model="booking.bookingWaypointPois"
      />
    </b-form-group>

    <validation-provider
      #default="{ errors }"
      name="Drop Off"
      rules="required"
    >
      <b-form-group
        label="Drop Off"
        label-for="drop-off"
      >
        <v-select
          taggable
          :clearable="false"
          :options="pointOfInterestList"
          :reduce="(poi) => poi.name"
          @input="() => storeBookingData()"
          input-id="drop-off"
          label="name"
          v-model="booking.dropoffPoiName"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>
  </b-col>
</template>
<script>
import { BCol, BFormGroup } from 'bootstrap-vue';
import { computed } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import store from '@/store';
import useBookingUtils from '@/modules/booking/composables/useBookingUtils';
import vSelect from 'vue-select';

export default {
  setup() {
    const { storeBooking } = useBookingUtils();

    const pointOfInterestList = store.getters['poi/poiList'];
    const booking = computed(() => store.getters['booking/booking']);

    const includeWayPoint = () => {
      booking.value.includeWaypointPois = booking.value.bookingWaypointPois.length === 0 ? false : true;
      storeBookingData();
    };

    const storeBookingData = () => {
      storeBooking(booking.value);
    };

    return {
      booking,
      pointOfInterestList,

      //Method
      includeWayPoint,
      storeBookingData,
    };
  },
  components: {
    BCol,
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
};
</script>
