var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"taxiFormRef",staticClass:"repeater-form",style:({ height: _vm.trHeight })},_vm._l((_vm.booking.bookingTaxis),function(bookingTaxi,index){return _c('b-row',{key:index,ref:"taxiItemForm",refInFor:true,staticClass:"pb-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex border rounded"},[_c('b-row',{staticClass:"flex-grow-1 p-2"},[_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"Taxi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Taxi","label-for":"taxi"}},[_c('v-select',{attrs:{"clearable":false,"options":_vm.taxiList,"label":"licenceNum"},on:{"input":function (taxi) { return _vm.taxiSelected(index, taxi); }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
var licenceNum = ref.licenceNum;
return [_c('span',[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(licenceNum))]),_vm._v(" - "),_c('small',[_vm._v(_vm._s((firstName + " " + lastName)))])])]}},{key:"selected-option",fn:function(ref){
var firstName = ref.firstName;
var lastName = ref.lastName;
var licenceNum = ref.licenceNum;
return [_c('span',[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(licenceNum))]),_vm._v(" - "),_c('small',[_vm._v(_vm._s((firstName + " " + lastName)))])])]}}],null,true),model:{value:(bookingTaxi.taxi),callback:function ($$v) {_vm.$set(bookingTaxi, "taxi", $$v)},expression:"bookingTaxi.taxi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Vehicle","label-for":"vehicle"}},[_c('v-select',{attrs:{"clearable":false,"options":_vm.taxiVehiclesList[index],"label":"model"},on:{"input":function (vehicle) { return _vm.vehicleSelected(index, vehicle); }},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v("Please, select the taxi")]},proxy:true},{key:"option",fn:function(ref){
var model = ref.model;
var regNum = ref.regNum;
return [_c('span',[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(regNum))]),_vm._v(" - "),_c('small',[_vm._v(_vm._s(model))])])]}},{key:"selected-option",fn:function(ref){
var model = ref.model;
var regNum = ref.regNum;
return [_c('span',[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(regNum))]),_vm._v(" - "),_c('small',[_vm._v(_vm._s(model))])])]}}],null,true),model:{value:(bookingTaxi.vehicle),callback:function ($$v) {_vm.$set(bookingTaxi, "vehicle", $$v)},expression:"bookingTaxi.vehicle"}})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Waiting Time (min)","label-for":"waiting-time"}},[_c('v-select',{attrs:{"options":_vm.waitTimeRateList,"id":"waiting-time","label":"timeInMin"},on:{"input":function (waitTime) { return _vm.waitingTimeSelected(index, waitTime); }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var timeInMin = ref.timeInMin;
var fee = ref.fee;
return [_c('span',[_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(timeInMin)+" min")]),_vm._v(" - £"+_vm._s(fee)+" ")])]}},{key:"selected-option",fn:function(ref){
var timeInMin = ref.timeInMin;
return [_c('span',[_vm._v(_vm._s(timeInMin)+" min")])]}}],null,true),model:{value:(bookingTaxi.waitingTimeInMin),callback:function ($$v) {_vm.$set(bookingTaxi, "waitingTimeInMin", $$v)},expression:"bookingTaxi.waitingTimeInMin"}})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[(
                  _vm.booking.bookingWaypointPois.length > 0 ||
                  !_vm.booking.bookingType.isCrew
                )?_c('validation-provider',{attrs:{"name":"Meter Reader","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Meter Reader (£)","label-for":"meter-reader"}},[_c('b-form-input',{attrs:{"id":"meater-reader","min":"1","type":"number"},on:{"input":function (meterReader) { return _vm.applyMeterReader(index, meterReader); },},model:{value:(bookingTaxi.meterReaderFee),callback:function ($$v) {_vm.$set(bookingTaxi, "meterReaderFee", $$v)},expression:"bookingTaxi.meterReaderFee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e()],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Wheelchairs","label-for":"wheelchair-num"}},[_c('b-form-input',{attrs:{"id":"wheelchair-num","type":"number","min":"0"},on:{"input":function (wheelchair) { return _vm.applyWheelchair(index, wheelchair); }},model:{value:(bookingTaxi.wheelchairNum),callback:function ($$v) {_vm.$set(bookingTaxi, "wheelchairNum", $$v)},expression:"bookingTaxi.wheelchairNum"}})],1)],1),(
                !_vm.booking.bookingType.isGha && !_vm.booking.bookingType.isGibAir
              )?_c('b-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"Meter Reader","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Baggage (kg)","label-for":"baggage"}},[_c('b-form-input',{attrs:{"id":"baggage","min":"1","type":"number"},on:{"input":function (baggageKg) { return _vm.applyBaggageKg(index, baggageKg); },},model:{value:(bookingTaxi.heavyBaggageKg),callback:function ($$v) {_vm.$set(bookingTaxi, "heavyBaggageKg", $$v)},expression:"bookingTaxi.heavyBaggageKg"}})],1)]}}],null,true)})],1):_vm._e()],1),(_vm.booking.bookingTaxis.length > 1)?_c('div',{staticClass:"pt-50 pr-50"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"size":"16","icon":"XIcon"},on:{"click":function($event){return _vm.removeTaxi(index)}}})],1):_vm._e()],1)])],1)}),1),_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.addNewTaxi}},[_vm._v(" Add Taxi ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }