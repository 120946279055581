<template>
  <section class="pl-1">
    <!-- Issued by Staff -->
    <validation-provider
      #default="{ errors }"
      name="Issued by"
      rules="required"
    >
      <b-form-group
        label="Issued by"
        label-for="issued-staff"
      >
        <v-select
          :clearable="false"
          :options="staffList"
          :reduce="(staff) => staff.id"
          @input="storeBookingData"
          input-id="issued-staff"
          label="firstname"
          v-model="booking.issuedByStaffMemberId"
        >
          <template #option="{ firstname, lastname }">
            <span> {{ `${firstname} ${lastname}` }}</span>
          </template>

          <template #selected-option="{ firstname, lastname }">
            <span> {{ `${firstname} ${lastname}` }}</span>
          </template>
        </v-select>
        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>

    <!-- Dispatched by Staff -->
    <validation-provider
      #default="{ errors }"
      name="Dispatched by"
      rules="required"
    >
      <b-form-group
        label="Dispatched by"
        label-for="dispatched-staff"
      >
        <v-select
          :clearable="false"
          :options="staffList"
          :reduce="(staff) => staff.id"
          @input="storeBookingData"
          input-id="dispatched-staff"
          label="firstname"
          v-model="booking.dispatchedByStaffMemberId"
        >
          <template #option="{ firstname, lastname }">
            <span> {{ `${firstname} ${lastname}` }}</span>
          </template>

          <template #selected-option="{ firstname, lastname }">
            <span> {{ `${firstname} ${lastname}` }}</span>
          </template>
        </v-select>
        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>
  </section>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue';
import { computed } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import flatPickr from 'vue-flatpickr-component';
import store from '@/store';
import useBookingUtils from '@/modules/booking/composables/useBookingUtils';
import vSelect from 'vue-select';

export default {
  setup() {
    const { storeBooking } = useBookingUtils();

    const staffList = store.getters['staff/staffList'];
    const booking = computed(() => store.getters['booking/booking']);

    const storeBookingData = () => {
      storeBooking(booking.value);
    };

    return {
      // Reactive
      booking,
      staffList,

      //Method
      storeBookingData,
    };
  },
  components: {
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    flatPickr,
    ValidationProvider,
    vSelect,
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.taxi-vehicle-select .vs__selected {
  width: 95%;
}

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}

.booking-source-select {
  width: 100%;
  max-width: 197px;
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '~@core/scss/base/components/variables-dark';

.booking-header {
  .title {
    width: 115px;
  }
}

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {

  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
