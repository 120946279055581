<template>
  <section>
    <div
      class="d-flex flex-sm-row flex-column justify-content-between align-items-sm-center"
    >
      <!-- Voucher Number -->
      <b-col sm="6">
        <validation-provider
          #default="{ errors }"
          name="Voucher"
          rules="required"
        >
          <b-form-group
            label="Voucher"
            label-for="voucher"
          >
            <b-input-group
              :class="booking.id !== null ? 'disabled' : null"
              class="input-group-merge invoice-edit-input-group"
            >
              <b-input-group-prepend is-text>
                <feather-icon icon="HashIcon" />
              </b-input-group-prepend>
              <b-form-input
                @input="storeBookingData"
                :disabled="booking.id !== null"
                id="voucher"
                v-model="booking.voucherNum"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Booking Date Added -->
      <b-col
        sm="6"
        v-if="booking.bookingDateAddedUtc"
      >
        <div class="d-flex align-items-center justify-content-sm-end">
          <b-form-group
            label="Booking Date Added"
            label-for="booked"
          >
            <b-form-input
              disabled
              id="date-added"
              v-model="booking.bookingDateAddedUtc"
            />
          </b-form-group>
        </div>
      </b-col>

      <!-- Booking Date -->
      <b-col
        sm="6"
        v-if="!booking.bookingDateAddedUtc"
      >
        <div class="d-flex align-items-center justify-content-sm-end">
          <validation-provider
            #default="{ errors }"
            name="Booking Date"
            rules="required"
          >
            <b-form-group
              label="Booking Date"
              label-for="booked"
            >
              <flat-pickr
                v-model="booking.bookedForDateUtc"
                class="form-control"
                :state="errors.length > 0 ? false : null"
                @input="storeBookingData"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </div>
      </b-col>
    </div>

    <div
      v-if="booking.bookingDateAddedUtc"
      class="d-flex flex-sm-row flex-column justify-content-sm-end"
    >
      <!-- Booking Date -->
      <b-col sm="6">
        <div class="d-flex align-items-center justify-content-sm-end">
          <validation-provider
            #default="{ errors }"
            name="Booking Date"
            rules="required"
          >
            <b-form-group
              label="Booking Date"
              label-for="booked"
            >
              <flat-pickr
                v-model="booking.bookedForDateUtc"
                class="form-control"
                :state="errors.length > 0 ? false : null"
                @input="storeBookingData"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </div>
      </b-col>
    </div>

    <div class="d-flex flex-sm-row flex-column justify-content-sm-end">
      <!-- Booking Source Type -->
      <b-col sm="6">
        <div class="d-flex align-items-center justify-content-sm-end">
          <validation-provider
            #default="{ errors }"
            name="Booking Source"
            rules="required"
            class="booking-source-select"
          >
            <b-form-group
              label="Booking Source"
              label-for="booking-source"
            >
              <v-select
                :clearable="false"
                :options="bookingSourceTypeList"
                :reduce="(source) => source.id"
                @input="storeBookingData"
                input-id="booking-source"
                label="name"
                v-model="booking.bookingSourceTypeId"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </div>
      </b-col>
    </div>
  </section>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue';
import { computed } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import flatPickr from 'vue-flatpickr-component';
import store from '@/store';
import useBookingUtils from '@/modules/booking/composables/useBookingUtils';
import vSelect from 'vue-select';
import useUtils from '@/composables/useUtils';

export default {
  setup() {
    const { formatDateDdMmYyyyHi, convertUtcToLocalDate } = useUtils();
    const { storeBooking } = useBookingUtils();

    const bookingSourceTypeList =
      store.getters['bookingSourceType/sourceTypeList'];
    const staffList = store.getters['staff/staffList'];
    const booking = computed(() => store.getters['booking/booking']);

    const formatBookingDates = () => {
      booking.value.bookingDateAddedUtc =
        booking.value.bookingDateAddedUtc !== null
          ? formatDateDdMmYyyyHi(
              convertUtcToLocalDate(booking.value.bookingDateAddedUtc)
            )
          : null;

      booking.value.bookedForDateUtc =
        booking.value.bookedForDateUtc !== null
          ? convertUtcToLocalDate(booking.value.bookedForDateUtc)
          : new Date();
    };

    const storeBookingData = () => {
      storeBooking(booking.value);
    };

    const assignRandomVoucherNum = () => {
      booking.value.voucherNum = Math.random()
        .toString(36)
        .toUpperCase()
        .slice(2, 7);
    };

    formatBookingDates();
    if (booking.value.bookingType.isGibAir) assignRandomVoucherNum();
    flatpickr.setDefaults({ dateFormat: 'd/m/Y H:i', enableTime: true });

    return {
      // Reactive
      booking,
      bookingSourceTypeList,
      staffList,

      //Method
      storeBookingData,
    };
  },
  components: {
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    flatPickr,
    ValidationProvider,
    vSelect,
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.flatpickr-calendar.hasTime .flatpickr-time {
  border-top: 1px solid #e6e6e6;
  display: none;
}

.taxi-vehicle-select .vs__selected {
  width: 95%;
}

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}

.booking-source-select {
  width: 100%;
  max-width: 197px;
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '~@core/scss/base/components/variables-dark';

.booking-header {
  .title {
    width: 115px;
  }
}

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
